import PropTypes from 'prop-types'
import merge from 'lodash/merge'
import ReactApexChart from 'react-apexcharts'
// @mui
import { Card, CardHeader, Box } from '@mui/material'
// components
import { BaseOptionChart } from '../../../components/chart'

// ----------------------------------------------------------------------


ManagerWiseTrendsBarChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default function ManagerWiseTrendsBarChart({
  title,
  subheader,
  chartLabels,
  chartColors,
  chartData,
  ...other
}) {
  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    colors: chartColors,
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      labels: {
        trim: true,
        hideOverlappingLabels: false
      }
    }
  })

 const width = chartLabels.length
 console.log("Width calculation--",width,chartLabels)
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 3, pb: 1,overflow:'auto',maxWidth:'100%' }} dir="ltr">
        <ReactApexChart
          type="bar"
          series={chartData}
          options={chartOptions}
          height={364}
          width={(width*50)>700?(width*50):700}
        />
      </Box>
    </Card>
  )
}
